import React from 'react';
import styled, { withTheme, css } from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';

// Components
import { Space, Background, Card } from '../components';

// Styles
import {
	UnorderedList,
	UnorderedListItem,
	HeadLegal,
	TextLegal,
	ALegal,
	SubHeadLegal,
	Content,
	Section,
	OL,
	OI
} from '../styles';

const Faq = ({ theme }) => {
	const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
	return (
		<Content>
			<Section id="Faq">
				<Background
					color={theme.secondaryColor}
					padding={{phone: '19px 19px 55px 19px', def: '79px 60px 100px 18px'}}
				>
					<Card
						spacing={spacing}
						margin={{phone: '0',  def: '0 0 0 -40px'}}
					>
						<HeadLegal>FAQ</HeadLegal>
						<SubHeadLegal>For Consumers</SubHeadLegal>
						<TextLegal bold textIndent>
							If I create an AgePass Key in my browser's 'normal' mode is my privacy compromised at all?
						</TextLegal>
						<TextLegal>We do not use cookies or other ways to compromise your security.</TextLegal>
						<TextLegal bold textIndent>
							Why do I have to verify my age?
						</TextLegal>
						<TextLegal>
							It is a legal requirement for adult content providers to verify your age if you visit a
							website that falls under the new regulation.
						</TextLegal>
						<TextLegal bold textIndent>
							Why do I need to enter a PIN?
						</TextLegal>
						<TextLegal>
							This feature is to secure your devices from use by other people who may have shared access.
						</TextLegal>
						<TextLegal bold textIndent>
							I use my web browser's incognito mode, why do I need to create an AgePass key in my
							browser's 'normal' mode?
						</TextLegal>
						<TextLegal>
							Incognito mode does not permit the retention of a key on your device. It means that all keys
							will be destroyed after you close the browser.
						</TextLegal>
						<TextLegal>
							However, we can allow you to create a retained key by setting a ‘Password’ and 4-digit ‘PIN’
							which can be re- entered whenever you open the incognito browser. Once in this session you
							will not need to re-enter anything. This then permits unhindered future access or with a
							4-digit PIN depending on the selected browsing preferences.{' '}
						</TextLegal>
						<SubHeadLegal>For Content Providers</SubHeadLegal>
						<TextLegal bold textIndent>
							Do I need to pay to use AgePass?
						</TextLegal>
						<TextLegal>
							AgePass is free for Content Providers to use. For Consumers, it may be free depending on the
							method of age verification chosen. AgePass charges consumers for the most privacy-centric
							age verification options such as the Age Verification Card.
						</TextLegal>
						<TextLegal bold textIndent>
							How does AgePass work?
						</TextLegal>
						<TextLegal>Our technology works through the combination of a few critical processes:</TextLegal>
						<UnorderedList>
							<UnorderedListItem>
								The separation of someone making a trusted statement about you, and the ongoing use of
								that trusted statement
							</UnorderedListItem>
							<UnorderedListItem>
								Giving control over the use of trusted statements to the consumers that are the subject
								of the statement
							</UnorderedListItem>
							<UnorderedListItem>
								Using a blockchain with private messaging capabilities to manage the use of and
								verification of, trusted statements.
							</UnorderedListItem>
						</UnorderedList>
						<TextLegal>
							In our situation 'trusted statements' are simply statements about a consumer’s age;
							specifically, "They are 18 or older". Once a consumer has validated their age with a trusted
							third-party data validator (that AgePass has integrated to), AgePass will ensure that this
							trusted digital statement is logged (anonymously) on our blockchain. We then provide the
							consumer the only key that enables its ongoing use.
						</TextLegal>
						<TextLegal>
							If a consumer subsequently tries to visit a website that requires age-verification which has
							integrated with AgePass technology, they simply need to electronically 'pass' their key to
							our blockchain via the website they are trying to access. The key is cryptographically
							secured so that neither the website nor AgePass can read the contents. Once the key is
							passed to our blockchain and the credentials match previously logged credentials, the
							blockchain will produce a new one-time code and pass it to the website. Receipt of these
							one-time codes are evidence by the website owner that the consumer is "18 or over", else a
							one-time code would not have been produced.
						</TextLegal>
						<TextLegal>
							Only the consumer is in possession of all the information. If a single consumer visited one
							website 10 times, the website would receive 10 unique one-off codes from AgePass.
						</TextLegal>

						<TextLegal>
							The original validator has no record or visibility as to whether their trusted digital token
							is ever used. The website cannot determine who the visitor is pre or post-validation.
							AgePass has no access to Consumer credentials.
						</TextLegal>

						<TextLegal>Only the consumer has the complete picture.</TextLegal>
						<TextLegal bold textIndent>
							How does your solution work with our sites?
						</TextLegal>
						<TextLegal>
							Any website can use the AgePass technology for age verification compliance. We offer a range
							of different integration options depending on what technology you are running. These range
							from server-side integrations (e.g. PHP), to simple WordPress plugins, and page-only
							JavaScript.
						</TextLegal>
						<TextLegal>
							All you need to do is decide what integration method works best for you and whether you want
							to use our geolocation detector (to ascertain origin of the inbound viewer), then integrate
							using our online documentation:{' '}
							<ALegal
								href="https://avsecure.com/developers/getting-started"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://avsecure.com/developers/getting-started
							</ALegal>.
						</TextLegal>
						<TextLegal>
							Once a consumer has established an AgePass account, when they visit your website our code
							will be initiated, and they will gain verified access.
						</TextLegal>
						<TextLegal bold textIndent>
							How does your solution work with our sites?
						</TextLegal>
						<TextLegal>
							If you choose to integrate to AgePass technology using server-side integration we will need
							a record of the public-key you create for this process. The sign-up process is extremely
							quick and simply a matter of completing a few fields in a web-form:{' '}
							<ALegal
								href="https://avsecure.com/developers/getting-started"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://avsecure.com/developers/getting-started
							</ALegal>.
						</TextLegal>
						<TextLegal bold textIndent>
							What is the integration process?
						</TextLegal>
						<TextLegal>
							We offer a variety of different integration options for website owners. These include PHP
							and .NET integrations, WordPress plugins, and JavaScript page-only integration. Once you
							have decided the breadth of the service you wish to integrate to your site (e.g. do you want
							you use the AgePass geolocation detector as well as age-verification software) and which
							integration method best suits your business and technology you simply need to follow our
							online documentation instructions to integrate.
						</TextLegal>
						<TextLegal bold textIndent>
							What makes your solution better than the rest?
						</TextLegal>
						<TextLegal>
							Our solution has been tailor-made to meet the requirements of maintaining consumer
							anonymity, whilst abiding by the age verification requirements of the regulator. Our
							solution provides anonymisation techniques that are industry leading, thereby ensuring that
							additional risks to consumer privacy resulting from the Digital Economy Act are minimised.
						</TextLegal>
						<TextLegal>
							AgePass does not 'know' its customers. It requests consumers perform an age-authentication
							check with trusted third-parties (through a choice of options) and once validated our
							software allows consumers to create an anonymous access account that generates one-off-use
							tokens when they visit websites in the future. The generation of these tokens, which are
							passed to the website providers, ensure that the consumers aren’t tracked by our software.
						</TextLegal>
						<Space height="120" />
					</Card>
				</Background>
			</Section>
		</Content>
	);
};

export default withTheme(Faq);

const spacing = css`
    padding: 52px 50px 40px 50px;

		${media.laptop`
        padding: 36px 42px 20px 42px;
    `}
    
    ${media.phone`
        padding: 36px 42px 20px 42px;
    `}
`;

