import React from 'react';
import { Page } from '../components';
import Faq from '../sections/Faq'


const FaqPage = () => (
	<Page>
		<Faq />
	</Page>
);

export default FaqPage;
